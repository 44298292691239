<template>
	<div class="p-5 flex flex-col items-center">
		<img src="@/assets/NUBETECH.png" alt="" class="p-2 h-16" />
		<n-card title="系統激活" hideOk hideCancel>
			<!-- card content -->
			<qrcode :value="deviceId" :options="{ width: 200 }" tag="img" class="mx-auto rounded-2xl" />
			<!-- card content appInfo -->
			<div class="text-muted pt-5">
				<img src="@/assets/app.png" alt="" />
				<p class="text-sm py-2">{{ deviceId }}</p>
				<p class="text-xs">v{{ version }} ({{ appType }})</p>
			</div>
		</n-card>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import PackageInfo from '../../package.json'

export default {
	data() {
		return {
			deviceId: localStorage.getItem('deviceId'),
			appType: PackageInfo.appType,
			version: PackageInfo.version,
		}
	},

	computed: {
		...mapGetters(['currentDevice']),
	},

	watch: {
		currentDevice: async function (device) {
			try {
				if (device.status !== 'ACTIVE') return
				this.$store.dispatch('setLoading', { status: true })
				await this.$store.dispatch('bindCurrentShop', { id: device.shop.id })
				await this.$store.dispatch('bindCurrentAdjustment', { shopId: device.shop.id, deviceId: device.id })
				await this.$store.dispatch('bindCatalog')
				await this.$store.dispatch('bindInventory', { shopId: device.shop.id })
				this.$store.dispatch('setLoading', { status: false })
				return this.$router.push('/signin')
			} catch (e) {
				console.error(e)
				this.$store.dispatch('setError', { message: e.message, status: true })
				this.$store.dispatch('setLoading', { status: false })
			}
		},
	},
}
</script>
